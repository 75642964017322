import React from "react";
import "./styles.css";
import { testimonialData } from "../../data/testimonialData";
import TestimonyCard from "../Cards/TestimonyCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";

function Testimonials() {
  return (
    <section className="testimonial--section">
      <div className="row">
        <div className="container">
          <div className="testimonial">
            <h4>testimonials</h4>
            <h2>what people say</h2>
          </div>
          <div className="testimonial--cards">
            <Splide
              options={{
                gap: 30,
                perPage: 3,
                rewind: true,
                width: "100%",
                autoplay: true,
                arrows: false,
                breakpoints: {
                  768: {
                    perPage: 1,
                  },
                },
              }}
            >
              {testimonialData.map((testimony) => (
                <SplideSlide key={testimony.id} >
                  <TestimonyCard testimony={testimony} />
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
