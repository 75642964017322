import React, { useState } from "react";
import { FaRegEnvelope } from "react-icons/fa6";
import { IoPersonOutline } from "react-icons/io5";
import { IoMdPhonePortrait } from "react-icons/io";
import { MdOutlineMessage } from "react-icons/md";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

function ContactUs() {
  const initials = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };
  const [contactData, setContactData] = useState(initials);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setContactData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const postData = async (data) => {
    setIsLoading(true);
    try {
      const response = await fetch("contact", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        return toast.error(`Message not sent. An error occured`);
      }
      toast.success("Message sent successfully");
      return response.json();
    } catch (error) {
      console.log(error);
      toast.error("An error occured");
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !contactData.name ||
      !contactData.email ||
      !contactData.phone ||
      !contactData.message
    ) {
      return toast.error("All fields are required");
    }
    if (contactData.name.length < 3) {
      return toast.error("Please enter a valid name");
    }
    if (
      !contactData.email.match(
        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
      )
    ) {
      return toast.error("Please enter a valide email");
    }
    if (!contactData.phone.match(/^\d{9,10}$/)) {
      return toast.error("Please enter a valid phone number");
    }

    await postData(contactData);
  };

  return (
    <section id="contact">
      <div className="row">
        <div className="container">
          <div className="contact--header">
            <h4>contact us</h4>
            <h2>get in touch with crenly team</h2>
            <p>
              Interested in eLearning or transforming your learning experience?
              Reach out to us today to explore our innovative e-learning
              solutions tailored to your needs. Let's embark on a journey of
              knowledge together.
            </p>
          </div>
          <div className="contact--form">
            <form action="" onSubmit={handleSubmit}>
              <div className="user--info">
                <div className="form--group">
                  <IoPersonOutline size={20} className="input--icon" />
                  <input
                    type="text"
                    name="name"
                    id=""
                    placeholder="Your Name"
                    onChange={handleInputChange}
                    value={contactData.name}
                  />
                </div>
                <div className="form--group">
                  <FaRegEnvelope size={20} className="input--icon" />

                  <input
                    type="email"
                    name="email"
                    id=""
                    placeholder="E-mail"
                    onChange={handleInputChange}
                    value={contactData.email}
                  />
                </div>
                <div className="form--group">
                  <IoMdPhonePortrait size={20} className="input--icon" />

                  <input
                    type="phone"
                    name="phone"
                    id=""
                    placeholder="Phone"
                    onChange={handleInputChange}
                    value={contactData.phone}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className="form--group--textarea">
                <MdOutlineMessage size={20} className="input--icon" />

                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Your Message"
                  onChange={handleInputChange}
                  value={contactData.message}
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="contact--btn btn--primary"
              >
                {isLoading ? (
                  <>
                    <span>
                      <ClipLoader color="#fff" size={25} />
                    </span>{" "}
                    <span>SENDING MESSAGE</span>
                  </>
                ) : (
                  <span>SEND MESSAGE</span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
