import React from "react";
import Header from "../../LandingPage/Header";
import Subscribe from "../../LandingPage/Subscribe";
import WhyChooseUs from "../../LandingPage/WhyChooseUs";
import Testimonials from "../../LandingPage/Testimonials";
import ContactUs from "../../LandingPage/ContactUs";
import Footer from "../../LandingPage/Footer";
import Navbar from "../../LandingPage/Navbar";

function HomePage() {
  return (
    <div>
      <Navbar />
      <Header />
      <Subscribe />
      <WhyChooseUs />
      <Testimonials />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default HomePage;
