import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { NavLink } from "react-router-dom";
import SmoothScroll from "../../SmoothScroll";

function Navbar() {
  const [showNavbar, setShowNavbar] = useState(false);
  const [toggleHamburger, setToggleHamburger] = useState(false);
  const [activeItem, setActiveItem] = useState("header");
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);

  function toggleActiveItem(location) {
    setActiveItem(location);
  }
  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("header");
      const about = document.getElementById("about");
      const products = document.getElementById("products");
      const contact = document.getElementById("contact");
      const items = [header, about, products, contact];
      items.forEach((cur) => {
        // console.log(cur.id);
        const bounding = cur.getBoundingClientRect();
        //checking if element is in view
        if (
          bounding.top <=50 &&
          bounding.bottom >=50
        ) {
          toggleActiveItem(cur.id)
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeItem]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= 768 && window.pageYOffset > 100) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    // const activeNav = document.querySelector(".nav--links ul");
    // activeNav.classList.toggle("links--active");
    setToggleHamburger(!toggleHamburger);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        // console.log(event)
        setIsOpen(false);
        setToggleHamburger(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleHamburger]);

  return (
    <nav className="nav">
      <div className="row navbar" ref={navbarRef}>
        <div
          className="logo"
          style={{ display: "flex", alignItems: "center", gap: 5 }}
        >
          <img
            src={`${showNavbar ? "./favicon.ico" : "./logo.png"}`}
            alt="logo"
          />
          <h2>CRENLY</h2>
        </div>
        <div className="nav--links">
          <ul className={`links ${isOpen ? "links--active" : ""}`}>
            <li>
              <NavLink
                className={activeItem === "header" && "activeNavLink"}
                to={"#home"}
                onClick={() => {
                  toggleActiveItem("header");
                  SmoothScroll("header");
                }}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className={activeItem === "products" && "activeNavLink"}
                to={"#produts"}
                onClick={() => {
                  toggleActiveItem("products");
                  SmoothScroll("products");
                }}
              >
                Products
              </NavLink>
            </li>
            <li>
              <NavLink
                className={activeItem === "about" && "activeNavLink"}
                to={"#about"}
                onClick={() => {
                  toggleActiveItem("about");
                  SmoothScroll("about");
                }}
              >
                About
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to={"#blog"}
                className={`${showNavbar ? "navbar--visible--links" : ""}`}
              >
                BLOG
              </NavLink>
            </li> */}

            <li>
              <NavLink
                className={activeItem === "contact" && "activeNavLink"}
                to={"#contacts"}
                onClick={() => {
                  toggleActiveItem("contact");
                  SmoothScroll("contact");
                }}
                // onScroll={}
              >
                Contacts
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          className={`hamburger ${isOpen && "hamburger--icon"}`}
          onClick={handleToggle}
        >
          <div className={`line ${isOpen && "arrow--shape"}`}></div>
          <div className={`line ${isOpen && "line--middle"}`}></div>
          <div className={`line ${isOpen && "arrow--shape"}`}></div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
