import React, { useState, useEffect } from "react";
import { FaFacebook, FaLinkedin, FaTwitter, FaWhatsapp } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa6";
import SmoothScroll from "../../SmoothScroll";

function Footer() {
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer>
      <div className="row">
        <div className="container">
          <div className="footer--container">
            <div className="footer--about">
              {/* <div className="logo--and--name">
                <img src="./logo.png" alt="logo" />
                <h2>Crenly</h2>
              </div> */}
              <h3>Join Crenly Today</h3>
              <p>
                Welcome to Crenly, where innovation meets education!
                Revolutionize your learning experience with our cutting-edge
                electronic learning solutions designed to empower learners of
                all ages and backgrounds. Say goodbye to traditional learning
                constraints and hello to limitless possibilities with Crenly.
              </p>
            </div>
            <div className="contacts">
              <h3>Contacts</h3>
              <h5>Email: </h5>
              <p className="email">
                <a href="mailto:info@crenly.com">info@crenly.com</a>
              </p>
              <h5>Phones: </h5>
              <p>
                <a href="tel:+233506081501">+233 050 608 1501</a>
              </p>
              <p>
                <a href="tel:+233506081501">+233 050 608 1501</a>
              </p>
            </div>
            <div className="footer--address">
              <h3>Address</h3>
              <ul>
                <li>Ghana</li>
                <li>Ashanti-Region</li>
                <li>Kumasi</li>
                <li>AKA-145-000</li>
              </ul>
            </div>
            <div className="connect">
              <h3>Let's Connect</h3>
              <ul>
                <li>
                  <NavLink to={"/fb"}>
                    <FaFacebook size={30} className="social--icon" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/fb"}>
                    <FaTwitter size={30} className="social--icon" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/fb"}>
                    <FaWhatsapp size={30} className="social--icon" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/fb"}>
                    <FaLinkedin size={30} className="social--icon" />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer--after row">
        <div className="footer--after--container">
          <div className="footer--row">
            <div>&copy; 2024 Crenly. All Rights Reserved</div>
            <div>
              <NavLink to={"/privacy"} className={"footer--privacy"}>
                Privacy
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`button--navigator ${
          showNavbar ? "navigator--visible" : ""
        }`}
        onClick={() => SmoothScroll("header")}
      >
        <FaArrowUp color={"white"} />
      </div>
    </footer>
  );
}

export default Footer;
