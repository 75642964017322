import {useEffect} from 'react'
import { useNavigate} from 'react-router-dom'

const ScrollToTop = () => {
const pathName= useNavigate()
useEffect(()=>{
    window.scrollTo(0,0)
},[pathName])
return null
}

export default ScrollToTop