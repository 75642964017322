
function SmoothScroll(Id) {

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  scrollToSection(Id);

  return null;
}



export default SmoothScroll;