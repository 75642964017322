import React from "react";
import "./styles.css";

function Subscribe() {
  return (
    <div className="subscribe" id="products">
      <div className="row" style={{ overflowX: "hidden" }}>
        <div
          className="subscribe--image"
          data-aos="fade-left"
          data-aos-once={true}
          data-aos-offset={200}
        >
          <img src="./bg/phone.png" alt="" />
        </div>
        <div className="subscribe--text">
          <h2>
            Unlock Your Learning Potential: Explore Crenly's Electronic
            Solutions!
          </h2>
          <p data-aos="fade-right" data-aos-once={true}>
            Empower your learning journey with our mobile app, designed to
            seamlessly integrate online lessons, interactive assignments, and
            direct communication with dedicated teachers.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
