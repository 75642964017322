export const testimonialData = [
  {
    id: 1,
    image_url: "./bg/testimonials-4-131x131.jpg",
    text: "I am thoroughly impressed. The user interface is intuitive and easy to navigate, making the learning experience smooth and enjoyable.",
    name: "Erica Frimpomaa",
    position: "Student",
  },

  {
    id: 2,
    image_url: "./bg/testimonials-2-131x131.jpg",
    text: "I recently started using your e-learning app and I must say, it has exceeded my expectations. The course variety is impressive and the content is of high quality!",
    name: "Wisdom Oppong",
    position: "Teacher",
  },
  {
    id: 3,
    image_url: "./bg/testimonials-3-131x131.jpg",
    text: "The wide range of courses offered is impressive and caters to many different interests and fields. The content is current and presented in a way that is easy to understand and retain",
    name: "Frimpong Williams",
    position: "Student",
  },
  {
    id: 4,
    image_url: "./bg/testimonials-5-131x131.jpg",
    text: "The interface is sleek and easy to use, and the courses are well-structured and informative. I appreciate the variety of multimedia resources available, which cater to different learning styles",
    name: "Dawud Mohammed",
    position: "Student",
  },
  {
    id: 6,
    image_url: "./bg/testimonials-6-131x131.jpg",
    text: "I recently began using your e-learning app and I must say, it has truly surpassed my expectations. The range of courses available is remarkable, and the quality of the content is outstanding!",
    name: "Abigail Opoku",
    position: "Student",
  },
  {
    id: 7,
    image_url: "./bg/testimonials-7-131x131.jpg",
    text: "This is an amazing app. Once I installed it, I was able to manage every aspect of successfully running my business. Thank you for such an amazing product!",
    name: "Samuel Newman",
    position: "Associate Teacher",
  },
];
