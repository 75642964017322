import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/homePage/HomePage";
import Delete from "./components/pages/privacy/Delete";
import Privacy from "./components/pages/privacy/Privacy";

import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { ToastContainer} from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "./ScrollToTop";


function App() {
  useEffect(() => {
    AOS.init({
      duration: 500, // Animation duration
      easing: "ease-in-sine",
      delay: 50, // Easing type
    });
  }, []);

  return (
    <div className="App">
      <ToastContainer newestOnTop={true} autoClose={5000} />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/delete" element={<Delete />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </div>
  );
}

export default App;
