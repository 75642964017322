import React from "react";

function TestimonyCard({ testimony }) {
  return (
    <div className="testimonial--card">
        <div className="image--and--text">
      <img src={testimony.image_url} alt="client" />
      <p>{testimony.text}</p>
        </div>
      <div className="name--and--position">
        <h3>{testimony.name}</h3>
        <p>{testimony.position}</p>
      </div>
    </div>
  );
}

export default TestimonyCard;
