import React from "react";
import "./styles.css";
import playStore from "../../play.png";
import appStore from "../../app.png";

function Header() {
  return (
    <header id="header">
      <div className="row">
        <div className="header--row">
          <div
            className="section header--text"
            data-aos="fade-up"
            data-aos-once={true}
          >
            <h1>make your life simpler</h1>
            <h3>
              <span>with</span> crenly today.
            </h3>

            <div className="store--icons">
              <a href="https://www.example.com">
                <img src={playStore} alt="" className="store--icon" />
              </a>
              <a href="https://www.example.com">
                <img src={appStore} alt="" className="store--icon" />
              </a>
            </div>

            {/* <div className="buttons">
              <Button
                text={"App Store"}
                type={"btn--full"}
                icon={<IoLogoApple size={20} />}
                path={"whyChooseUs"}
              /> */}
            {/* I am using this to create a space between the butons */}
            {/* <div className="spacer">hi</div>
              <Button
                text={"Google play"}
                type={"btn--ghost"}
                path={"contact"}
                icon={<IoLogoGooglePlaystore size={20} />}
              />
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
