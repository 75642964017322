import React from "react";
// import { GiBackwardTime } from "react-icons/gi";
import { FaArrowRight } from "react-icons/fa6";
import { ImPower } from "react-icons/im";
import { MdOutlineStarBorderPurple500 } from "react-icons/md";
import { FaConnectdevelop } from "react-icons/fa6";

function WhyChooseUs() {
  return (
    <section id="about">
      <div className="row">
        <div className="container">
          <div className="head">
            <h4>about our application</h4>
            <h2>why choose Crenly</h2>
            <p>
              Empower your learning journey with our innovative mobile app,
              seamlessly connects students and teachers, eliminating the need
              for travel and offering instant access to educational resources
              and assignments from the comfort of your device.
            </p>
          </div>
          <div className="why--choose--us--card--container">
            <div className="card" data-aos="zoom-in" data-aos-once={true}>
              <div className="icon--and--title">
                <FaConnectdevelop size={40} className="icon" />
                <h3>Connectivity</h3>
              </div>
              <p>
                Connecting students and teachers for an enriching educational
                experience.
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Engaging online lessons
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Interactive assignments
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Personalized guidance
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Collaborations
              </p>
            </div>
            <div
              className="card"
              data-aos="zoom-in"
              data-aos-once={true}
              data-aos-delay="200"
            >
              <div className="icon--and--title">
                <ImPower size={40} className="icon" />
                <h3>Collaborative</h3>
              </div>
              <p>
                Fostering collaborative learning, empowering students and
                teachers to engage and grow together.
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Empowering students
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Diverse subjects
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Academic Excellence
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Convenience
              </p>
            </div>
            <div
              className="card"
              data-aos="zoom-in"
              data-aos-once={true}
              data-aos-delay="300"
            >
              <div className="icon--and--title">
                <MdOutlineStarBorderPurple500 size={40} className="icon" />
                <h3>Exclusive</h3>
              </div>
              <p>
                Experience the transformative power of our app, a must-have tool
                that connects students and teachers.
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Limitless learning possibilities
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Interative Videos
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                On-demand videos
              </p>
              <p>
                <span>
                  <FaArrowRight />
                </span>{" "}
                Quick Feedback
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
