import React, { useState } from "react";
import "./privacyStyles.css";
import Preloader from "../../../Preloader";
import { toast } from "react-toastify";

function Delete() {
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [phone, setPhone] = useState("");

  const toggleAgreement = () => {
    setAgreeCheck(!agreeCheck);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    //return true for valid number
    const data = isNumberValid(phone);
    if (data[0]) {
      //send number to server
      postData("https://api.crenly.com/deleteaccount", {
        phonenumber: data[1],
      })
        .then((e) => {
          console.log(e.status);
          if (e.status === 200) {
            //hide loading and show the processing account deletion screen
            setDeleted(true);
          } else {
            //show main screen with an eccured
            setDeleted(false);
          }
        })
        .catch((err) => {
          console.log(err);
          //show main screen with an eccured
          setDeleted(false);
        });
    } else {
      toast.error(
        "Please enter a valid phonenumber without the country code. We are currenly available only Ghana."
      );
      // console.log("Please enter a valid phonenumber");
    }
  };

  async function postData(url = "", data = {}) {
    setLoading(true);
    try {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache",
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response;
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }

  function isNumberValid(phone) {
    //phone number must at least 9 characters the leading (0) can be ignored
    //phone number must be 9 or ten
    if (phone.length < 9 || phone.length > 10) return [false, null];
    //remove the leading (0) if the user provided one
    let newPhonenumber = null;
    if (phone.length === 9) {
      newPhonenumber = phone;
    } else {
      newPhonenumber = phone.substring(1);
    }
    if (newPhonenumber[0] === "0") return [false, null];
    for (let i = 0; i < newPhonenumber.length; i++) {
      if (Number.isNaN(parseInt(newPhonenumber[i]))) return [false, null];
    }
    //we now have 9 numbers, we will add the country code before sending to the server
    newPhonenumber = `233${newPhonenumber}`;
    return [true, newPhonenumber];
  }

  if (deleted) {
    return (
      <div
        style={{ textAlign: "center", marginTop: "5rem", padding: "2rem 1rem" }}
      >
        <img src="./logo.png" alt="logo" width="100" />
        <br />
        <h2>Crenly</h2>
        <p>
          Your request to delete your account is being processed, We will soon
          send you an SMS via the number you provided to confirm the account
          deletion process. Thank you for using our service
        </p>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="delete--container">
          <div className="box">
            <img src="logo.png" alt="" width="100" />
            <br />
            <h2>Crenly</h2>
            <h2>Delete My Account</h2>
            <form onSubmit={submitForm}>
              <div className="inputBox">
                <label htmlFor="userName">
                  Enter the phonenumber associated with the account you want to
                  delete
                </label>
                <input
                  type="text"
                  name="phonenumber"
                  className="phonenumber"
                  placeholder="Type your number here"
                  required
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>
              <ol>
                <li>
                  We will send you a confirmation link via sms, use the link to
                  confirm the account deletion.
                </li>
                <br />
                <li>
                  Your data and any services pucrhased with this account will be
                  permanently deleted after 14 days. You won't be able to
                  recover your account after this period. However, sigining into
                  your account within the 14 days will automatically cancel the
                  account deletion.
                </li>
                <br />
                <li>
                  NB: Signing into your account within this period will
                  automatically stop your account from being deleted.
                </li>

                <li>
                  <h3>
                    I have read the sentences above and I agree.
                    <input
                      type="checkbox"
                      name=""
                      className="agree"
                      checked={agreeCheck}
                      onChange={toggleAgreement}
                    />
                  </h3>
                </li>
              </ol>

              <div></div>
              <div>
                <button
                  type="submit"
                  className="delete"
                  disabled={agreeCheck ? false : true}
                >
                  I agree, delete my account
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Delete;
